/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//Home Page
const greeting = {
  title: "Atharv Chavan",
  logo_name: "AVC",
  nickname: "AVC0706",
  subTitle:
    "A passionate individual who always thrive to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1klj47bdNvxwmDoqsXf9K3xEgoN6jyh70/view",
  portfolio_repository: "https://github.com/AVC0706",
};

const socialMediaLinks = [
	/* Your Social Media Link */
	// github: "https://github.com/ashutosh1919",
	// linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
	// gmail: "ashutoshhathidara98@gmail.com",
	// gitlab: "https://gitlab.com/ashutoshhathidara98",
	// facebook: "https://www.facebook.com/laymanbrother.19/",
	// twitter: "https://twitter.com/ashutosh_1919",
	// instagram: "https://www.instagram.com/layman_brother/"
  
	{
		name: "Github",
		link: "https://github.com/AVC0706",
		fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
		backgroundColor: '#181717' // Reference https://simpleicons.org/?q=github
	},
	{
		name: "LinkedIn",
		link: "https://www.linkedin.com/in/AVC0706/",
		fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
		backgroundColor: '#0077B5' // Reference https://simpleicons.org/?q=linkedin
	},
	{
		name: "Gmail",
		link: "mailto:atharvvijay4@gmail.com",
		fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
		backgroundColor: '#D14836' // Reference https://simpleicons.org/?q=gmail
	},

];

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
        "⚡ Experience of working with Computer Vision and NLP projects",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building resposive website front end using React-Redux",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
      
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },


      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud "
        
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },

        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
  
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/avc0706",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/avc0706",
    },

    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@atharv41",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/avc0706",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Pune Institute of Computer Technology",
      subtitle: "B.E. in Information Technology",
      logo_path: "pict_logo.png",
      alt_name: "PICT",
      duration: "2018 - Present",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
      ],
      website_link: "https://pict.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },

    {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },

  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as Fullstack and DL Developer. I love organising events and that is why I am also involved with our college student chapter.",
  header_image_path: "experience.svg",
  sections: [

    {
      title: "Internships",
      experiences: [
        {
          title: "Project Intern",
          company: "Research for Resurgence Foundation",
          company_url: "https://rfrfoundation.org/",
          logo_path: "rfrf_logo.png",
          duration: "July 2020 - Sept 2020",
          location: "Pune, Maharashtra",
          description:
            "I have worked on project of Developing a website to digitalise the working of the foundation .",
          color: "#ee3c26",
        },
  
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Student Volunteer",
          company: "PICT ACM Student Chapter",
          company_url: "https://pict.acm.org/#/home",
          logo_path: "pasc_logo.png",
          duration: "Jan 2019 - Present",
          location: "Pune, Maharashtra",
          description:
            "Active member of PASC( https://pict.acm.org/ ), a student chapter organization subsidiary of Association for Computing Machinery a worldwide learning society for computing. PASC has been awarded best ACM India Student Chapter Award for 3 times. As a member, I attend and also deliver sessions on various topics and technologies. Few of my works include an MCQ Solving Platform developed for tech fest Pulzion'19 along with other team members.",
          color: "#4285F4",
        },
        {
          title: "Google Developer Group",
          company: "Google Developer Group",
          company_url: "https://gdgpune.org/",
          logo_path: "gdg_logo.png",
          location: "Pune, Maharashtra",
          description:
            "I have attended various GDG events and workshops in Pune.",
          color: "#D83B01",
        },
   
  
    
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "atharv.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Nodejs, Flask and Cloud .",
  },

  addressSection: {
    title: "Address",
    subtitle:
      "PUNE, MAHARASHTRA",
    avatar_image_path: "address_image.svg",
  },

};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
